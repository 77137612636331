import { RichTextStyleWrapper } from "block-system/components";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { styled } from "lib/theme";
import React from "react";
import { cn } from "utils/cn";

export const ProjectAuthWrapper = (
  props: React.ComponentPropsWithoutRef<"div">
) => {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      {...props}
      className={cn(
        "min-h-screen",
        interfacesTheme ? "bg-background" : "bg-zi-pageBackground",
        props.className
      )}
    />
  );
};

export const Content = styled.div`
  max-width: 400px;
  margin: 30px auto;
`;

export const RichText = styled(RichTextStyleWrapper)`
  text-align: center;
`;
