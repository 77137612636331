import { ProjectAuthType } from "@prisma/client";
import MaintenancePageComponent from "components/MaintenanceComponent";
import { AuthPage } from "components/ProjectAuth/AuthPage";
import { PublishedPage } from "components/PublishedPageSSR";
import PublishedProvider from "components/PublishedProvider";
import { VerifyBrowser } from "components/VerifyBrowser";
import { RecaptchaProvider } from "lib/hooks/RecaptchaProvider";
import { setProjectSession } from "lib/project-sessions/project-session-storage";
import type { InterfacesTheme } from "lib/theme/ThemeProvider";
import { GetServerSideProps } from "next";
import { isAuthenticatedForProject } from "server/auth";
import { getMaintenanceMode } from "server/config/edge";
import { prisma } from "server/data/prisma";
import { withProjectSessionSSR } from "server/hooks/withProjectSession";
import { withRequestContextGetServerSideProps } from "server/hooks/withRequestContext";
import { PageContentInclude } from "server/models/blocks";
import { getPage, toPageWithContent } from "server/models/pages";
import { getProjectFromSlugOrCustomDomain } from "server/models/projects";
import { getThemeByProjectId } from "server/models/theme";
import logger from "server/observability/logger";
import { getConsumerByProjectSessionData } from "server/operations/consumers/getConsumerByProjectSessionData";
import { meConsumerSchema } from "server/schemas/consumers";
import { pagePublicSchema } from "server/schemas/pages";
import {
  projectForPublishedLoginPageSchema,
  projectForPublishedPageSchema,
} from "server/schemas/projects";
import { getFeatureFlags } from "server/services/split/split";
import { AppError } from "server/types/errors";
import { SplitCheck, trpc } from "utils/trpc";
import { z } from "zod";
import type { PageWithContent } from "../../server/routers/pages";
import type { ProjectSessionData } from "../../server/sessions/project";

type Params = { publishedPageParams: [string, string] };

export type ProjectForPublishedPage = z.infer<
  typeof projectForPublishedPageSchema
>;

export type ProjectForPublishedLoginPage = z.infer<
  typeof projectForPublishedLoginPageSchema
>;

type PageForPublishedPage = z.infer<typeof pagePublicSchema>;

type PageForPublishedLoginPage = { id: PageForPublishedPage["id"] };

type PageForCaptchaVerificationPage = { id: PageForPublishedPage["id"] };

type MeConsumer = z.infer<typeof meConsumerSchema>;

type RenderMaintenanceModeProps = {
  maintenanceMode: true;
};

type RenderBrowserVerificationProps = {
  maintenanceMode: false;
  verifyBrowser: true;
  sessionId: string;
  page: PageForCaptchaVerificationPage;
};

type RenderAuthFormProps = {
  maintenanceMode: false;
  verifyBrowser?: boolean;
  authorized: false;
  sessionId: string;
  project: ProjectForPublishedLoginPage;
  page: PageForPublishedLoginPage;
  splitCheck: SplitCheck;
  theme: InterfacesTheme | null;
};

export type RenderPageProps = {
  maintenanceMode: false;
  authorized: true;
  sessionId: string;
  project: ProjectForPublishedPage;
  page: PageForPublishedPage;
  allPages: PageForPublishedPage[];
  consumer: MeConsumer | null;
  verifyBrowser: false;
  splitCheck: SplitCheck;
  theme: InterfacesTheme | null;
};

type Props =
  | RenderMaintenanceModeProps
  | RenderBrowserVerificationProps
  | RenderAuthFormProps
  | RenderPageProps;

export default function PublishedPageNextJSPage(props: Props) {
  const utils = trpc.useUtils();

  if (props.maintenanceMode) {
    return <MaintenancePageComponent />;
  }

  setProjectSession({
    sessionId: props.sessionId,
    pageId: "page" in props ? props.page.id : undefined,
  });

  if (props.verifyBrowser) {
    return (
      <RecaptchaProvider>
        <VerifyBrowser onSuccess={() => window.location.reload()} />
      </RecaptchaProvider>
    );
  }

  utils.split.check.setData({ projectId: props.project.id }, props.splitCheck);

  if ("authorized" in props && !props.authorized) {
    return (
      <PublishedProvider project={props.project} interfacesTheme={props.theme}>
        <RecaptchaProvider>
          <AuthPage
            project={props.project}
            refetch={() => window.location.reload()}
          />
        </RecaptchaProvider>
      </PublishedProvider>
    );
  }

  const { project, page, allPages, consumer, theme } = props;

  // TODO INTRFCS-2342
  // Hydrate the react-query client cache.
  //
  // This is a bit of a hack. We're using setData to hydrate instead of using
  // the [established trpc helpers](https://trpc.io/docs/client/nextjs/server-side-helpers#nextjs-example)
  // to hydrate the cache. I tried them a long time ago in a POC, and cannot
  // remember why they didn't work. I will be revisiting this to try them
  // again, and document why they fail if they do. In the meantime, this
  // works.

  if (!utils.projects.get.getData({ id: project.id })) {
    // @ts-ignore
    utils.projects.get.setData({ id: project.id }, project);
  }

  if (page && !utils.pages.get.getData({ id: page.id })) {
    utils.pages.get.setData({ id: page.id }, page);
  }

  if (allPages && !utils.pages.list.getData({ projectId: project.id })) {
    utils.pages.list.setData({ projectId: project.id }, allPages);
  }

  if (consumer && !utils.consumers.me.getData({ projectId: project.id })) {
    utils.consumers.me.setData({ projectId: project.id }, consumer);
  }

  return (
    <PublishedProvider project={project} interfacesTheme={theme}>
      <RecaptchaProvider>
        <PublishedPage isEmbed={false} project={project} page={page} />
      </RecaptchaProvider>
    </PublishedProvider>
  );
}

export const getServerSideProps: GetServerSideProps<Props, Params> =
  withRequestContextGetServerSideProps<Props, Params>(
    withProjectSessionSSR<Props, Params>(async ({ params, req }) => {
      if (await getMaintenanceMode()) {
        return {
          props: {
            maintenanceMode: true,
          },
        };
      }

      if (!params?.publishedPageParams) {
        return {
          notFound: true,
        };
      }

      const [projectSlugOrCustomDomain, pageSlug] = params.publishedPageParams;
      if (!projectSlugOrCustomDomain) {
        return {
          notFound: true,
        };
      }

      const projectQueryEnd = startPerformanceLogger(
        "projectQueryDuration",
        "SSR performance: Project query duration (ms)"
      );
      const project = await getProjectFromSlugOrCustomDomain(
        projectSlugOrCustomDomain
      );
      projectQueryEnd();

      if (!project) {
        return {
          notFound: true,
        };
      }

      const theme = await getThemeByProjectId(project.id);

      const currentPageQueryEnd = startPerformanceLogger(
        "currentPageQueryDuration",
        "SSR performance: Current page query duration (ms)"
      );
      const currentPage = await getCurrentPage({
        pageSlug,
        projectId: project.id,
        projectHomepageId: project.homepageId,
      });
      currentPageQueryEnd();

      if (!currentPage) {
        return {
          notFound: true,
        };
      }

      const splitCheckEnd = startPerformanceLogger(
        "splitCheckDuration",
        "SSR performance: Split check duration (ms)"
      );
      const splitCheck = await getFeatureFlags({
        zapierUser: undefined,
        project,
      });
      splitCheckEnd();

      const isAuthenticated = await isAuthenticatedForProject({
        project,
        session: req.projectSession,
      });
      if (!isAuthenticated) {
        req.projectSession.data = {
          ...req.projectSession.data,
          enableChatbot: false,
          projectId: project.id,
          consumerId: undefined,
        };
        await req.projectSession.save();

        return {
          props: {
            maintenanceMode: false,
            verifyBrowser: false,
            authorized: false,
            project: projectForPublishedLoginPageSchema.parse(project),
            page: { id: currentPage.id },
            sessionId: req.projectSession.id,
            splitCheck,
            theme,
          },
        };
      }

      const displayCaptcha =
        project.captchaEnabled &&
        project.authType === ProjectAuthType.None &&
        !req.projectSession.data.captchaTokenProperties?.valid;

      if (displayCaptcha) {
        req.projectSession.data = {
          ...req.projectSession.data,
          enableChatbot: false,
          projectId: project.id,
        };
        await req.projectSession.save();

        return {
          props: {
            maintenanceMode: false,
            verifyBrowser: true,
            sessionId: req.projectSession.id,
            page: { id: currentPage.id },
          },
        };
      }

      const pageQueryEnd = startPerformanceLogger(
        "pageQueryDuration",
        "SSR performance: Get all pages query duration (ms)"
      );
      const allPages = await prisma.page
        .findMany({
          where: { projectId: project.id },
          include: PageContentInclude,
        })
        .then((pages) => {
          return pages.map(toPageWithContent);
        });
      pageQueryEnd();

      // If the page contains a chatbot block, enable the chatbot for this session
      const enableChatbot = currentPage?.content.blocks.some(
        (blockOnPage) => blockOnPage.type === "chatbot-block"
      );

      const consumerQueryEnd = startPerformanceLogger(
        "consumerQueryDuration",
        "SSR performance: Consumer query duration (ms)"
      );
      const consumer = await getConsumer(req.projectSession.data);
      consumerQueryEnd();

      req.projectSession.data = {
        ...req.projectSession.data,
        enableChatbot,
        projectId: project.id,
      };
      await req.projectSession.save();

      return {
        props: {
          project: projectForPublishedPageSchema.parse(project),
          page: pagePublicSchema.parse(currentPage),
          allPages: pagePublicSchema.array().parse(allPages),
          consumer,
          sessionId: req.projectSession.id,
          authorized: true,
          verifyBrowser: false,
          maintenanceMode: false,
          splitCheck,
          theme,
        },
      };
    })
  );

/**
 * Logs the duration of a performance event. Returns a function that logs the duration.
 * @param key Object key that the duration value will be logged under with the pino logger
 * @param message Log message
 * @returns The end function that logs the duration
 *
 * @example
 * const end = startPerformanceLogger("pageQueryDuration", "SSR performance: Page query duration (ms)");
 * // ... do some work
 * end();
 */
function startPerformanceLogger(key: string, message: string): VoidFunction {
  const startTime = performance.now();

  const end = () => {
    const endTime = performance.now();
    const duration = endTime - startTime;
    logger().debug({ [key]: duration }, message);
  };

  return end;
}

async function getCurrentPage({
  pageSlug,
  projectId,
  projectHomepageId,
}: {
  pageSlug: string | undefined;
  projectId: string;
  projectHomepageId: string | null;
}) {
  if (pageSlug) {
    return await getPage({ slug: pageSlug, projectId });
  }

  let currentPage: PageWithContent | null = null;
  if (projectHomepageId) {
    currentPage = await getPage({ id: projectHomepageId });
  } else {
    currentPage = await getPage({ projectId });
  }

  return currentPage;
}

async function getConsumer(projectSessionData: ProjectSessionData) {
  try {
    return await getConsumerByProjectSessionData(projectSessionData);
  } catch (error) {
    const isAppError = error instanceof AppError;
    if (!isAppError) {
      throw error;
    }

    const isRecoverableError =
      error.code === "UNAUTHORIZED" || error.code === "NOT_FOUND";
    if (isRecoverableError) {
      return null;
    }

    throw error;
  }
}
