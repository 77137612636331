import { Controller, useForm } from "react-hook-form";

import { trpc } from "utils/trpc";

import { Spacer } from "@zapier/design-system";

import {
  Button,
  Field,
  FormContainer,
  TextInput,
} from "block-system/components";
import { z } from "zod";
import { Content } from "../presentational";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { ReCAPTCHA } from "components/ReCAPTCHA";

const FormDataSchema = z.object({
  email: z.string().min(1, "Required"),
  captcha: z.string(),
});
type FormData = z.infer<typeof FormDataSchema>;

export function Step1SendEmail(props: {
  projectId: string;
  next: () => void;
  loginError: string | null;
  captchaEnabled: boolean;
}) {
  const { mutateAsync: sendEmail } =
    trpc.projectAuth.managedConsumerAuthStart.useMutation();

  const {
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = useForm<FormData>({
    resolver: zodResolver(
      props.captchaEnabled
        ? FormDataSchema
        : FormDataSchema.omit({ captcha: true })
    ),
  });

  useEffect(() => {
    if (props.loginError) setError("email", { message: props.loginError });
  }, [props.loginError, setError]);

  const onSubmit = async ({ email, captcha }: FormData) => {
    await sendEmail({
      projectId: props.projectId,
      email,
      captcha,
    });
    props.next();
  };

  return (
    <Content>
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Field
                label="Log in with your email address"
                renderInput={(inputProps) => (
                  <TextInput
                    {...inputProps}
                    {...field}
                    type="email"
                    autoFocus={true}
                    placeholder="Email"
                  />
                )}
                error={errors.email?.message}
              />
            )}
          />
          {props.captchaEnabled ? (
            <>
              <Spacer height={20} />
              <Controller
                name="captcha"
                control={control}
                render={() => (
                  <Field
                    error={errors.captcha?.message?.toString()}
                    label="Captcha"
                    renderInput={() => (
                      <ReCAPTCHA
                        action="PROJECT_LOGIN"
                        onChange={(key) => {
                          setValue("captcha", key);
                        }}
                      />
                    )}
                  />
                )}
                rules={{
                  required: "Captcha is required",
                }}
              />
            </>
          ) : null}
          <Spacer height={30} />
          <Button type="submit" isLoading={isSubmitting || isSubmitSuccessful}>
            Get login code
          </Button>
        </form>
      </FormContainer>
    </Content>
  );
}
